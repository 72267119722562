import {call, put, select, takeLatest} from "redux-saga/effects";
import {selectDB, selectLogPage2} from "../selectors";
import {CashierWebDB} from "../../db/CashierWebDB";
import {IRedeemRewardOperation} from "../../data-model/types";
import {logRedeemPointsAction} from "../../constants";
import {logRedeemPointsFailure, logRedeemPointsSuccess} from "../actions";
import { LOG_ITEMS_COUNT } from "./log-add-points-saga";

const getRedeemPointsOperationsFromDB = (db: CashierWebDB, page = 0) =>{
  return db.RedeemRewardOperation
    .orderBy('created_at')
    .reverse()
    .offset(page * LOG_ITEMS_COUNT)
    .limit(LOG_ITEMS_COUNT)
    .with({customer: 'customer'});
};

function* retrieveLogRedeempointsFromDB() {
  const db: CashierWebDB = yield select(selectDB);
  const page:number=yield select(selectLogPage2);
  const redeemPointsOperations: IRedeemRewardOperation[] = yield call(getRedeemPointsOperationsFromDB, db,page);
  yield put(logRedeemPointsSuccess(redeemPointsOperations))
}

function* logRedeemPointsSaga() {
  try {
    yield* retrieveLogRedeempointsFromDB();

  } catch (e) {
    yield put(logRedeemPointsFailure(e));
  }

}

export function* watchlogRedeemPointsSaga() {
  yield takeLatest(logRedeemPointsAction.requested, logRedeemPointsSaga);
}
