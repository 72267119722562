import * as constants from '../../constants';
import {ISwitchAccountAction} from "../../types";
import {mutateState} from "../../helpers/mutate-state";
import {ICashier} from "../../data-model/types";

export interface ISwitchAccountReducerState {
  cashier:ICashier;
  requesting:boolean;
  modal:boolean;
}

const CASHIER='cashier';
const MODAL='modal';
const REQUESTING='requesting';

const initialState: ISwitchAccountReducerState = {
  [CASHIER]: '' as any,
  [MODAL]:false,
  [REQUESTING]:false,
};

const switchAccountReducer = (state: ISwitchAccountReducerState = initialState, action: ISwitchAccountAction): ISwitchAccountReducerState => {
  switch (action.type) {
    case constants.switchAccountAction.requested: {
      return mutateState(state, map => {
        map.set(CASHIER, (action as ISwitchAccountAction).payload);
        map.set(REQUESTING, true);

      });
    }
    case constants.switchAccountAction.fulfilled: {
      return mutateState(state, map => {
        map.set(REQUESTING, false);
      });
    }
    case constants.switchAccountAction.rejected: {
      return mutateState(state, map => {
        map.set(REQUESTING, false);
      });
    }
    case constants.switchModalAction: {
      return mutateState(state, map => {
        const oldModal= map.get(MODAL);
        map.set(MODAL, !oldModal);
      });
    }
    default:
      return state;
  }
};


export default switchAccountReducer;
