import ManagePoints from './ManagePoints';
import RedeemGifts from './RedeemGifts';
import {CURRENT_LANG, MANAGE_POINTS, pointsLog, REDEEM_GIFTS, redeemLog} from "../strings/App";
import Login from "./Login";
import Sync from "./Sync";
import logs from './logs';
import contactshopx from './contactshopx';
import switchAccount from './switchAccount';
import logout from './logout';
import axios from './axios';
import navbar from "./navbar";
import selectBranch from "./selectBranch";
import VerifyPasswordModal from "./VerifyPasswordModal";
export const en = {
  translations: {
    [MANAGE_POINTS]: 'Manage Points',
    [REDEEM_GIFTS]: 'Redeem Gifts',
    [CURRENT_LANG]: 'عربي',
    [pointsLog]: 'Points Log',
    [redeemLog]: 'Gifts Log',
  },
  [MANAGE_POINTS]: ManagePoints,
  [REDEEM_GIFTS]: RedeemGifts,
  Login,
  Sync,
  axios,
  logs,
  contactshopx,
  switchAccount,
  logout,
  navbar,
  selectBranch,
  VerifyPasswordModal,
};
