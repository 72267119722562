import * as constants from '../../constants';
import {
  IRedeemGiftAction,
  IRedeemGiftFailureAction,
  IRedeemGiftSuccessAction,
  IRedeemResponse,
  IResetRedeemAction
} from "../../types";
import {noop} from "redux-saga/utils";

export const redeemGift = (code: string, cb = noop): IRedeemGiftAction => ({
  type: constants.redeemGiftsAction.requested,
  payload: {
    code,
    cb,
  },
});

export const redeemGiftSuccess = (data: IRedeemResponse): IRedeemGiftSuccessAction => ({
  type: constants.redeemGiftsAction.fulfilled,
  payload: data,
});

export const redeemGiftFailure = (error: Error): IRedeemGiftFailureAction => ({
  type: constants.redeemGiftsAction.rejected,
  payload: error,
});

export const resetRedeem = (): IResetRedeemAction => ({
  type: constants.ACTION_RESET_REDEEM
});
