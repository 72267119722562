export const current = 'current';
export const transactions = 'transactions';
export const ranked = 'ranked';
export const month='month';
export const click='click';
export const switchAccountSuccessful = 'switchAccountSuccess';
export const passwordWrong = 'passwordWrong';
export const switchAccountTitle = 'switchAccountTitle';
export const loginIntoAccount = 'loginIntoAccount';
export const passwordPlaceholder = 'passwordPlaceholder';
