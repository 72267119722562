import {
  contact,
  contactMessage,
  contactTitle,
  haveProblem
} from "../strings/ContactShopx";

export default {
  [haveProblem]: "Have a problem?",
  [contact]: "Contact",
  [contactTitle]: "Contact Us",
  [contactMessage]: "To contact us, Please call us at 01022913161"
};
