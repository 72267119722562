import {call, put, select, takeLatest} from "redux-saga/effects";
import {selectDB, selectLogPage} from "../selectors";
import {IAddPointsOperation} from "../../data-model/types";
import {logAddPointsFailure, logAddPointsForComparisonSuccess, logAddPointsSuccess} from "../actions";
import {logAddPointsAction} from "../../constants";
import startOfDay from 'date-fns/start_of_day'
import format from 'date-fns/format'
import {ICashierWebDB} from "../../db/types";
import {ISyncActivity} from "../../types";
import {PendingSyncActivity} from "../../data-model/models/PendingSyncActivity";
import {SyncActivity} from "../../data-model/models/SyncActivity";

const getCustomerById = (db: ICashierWebDB, id: string) => db.Customer.where('id').equals(id).toArray();
const getCustomerByPhone = (db: ICashierWebDB, phone: string) => db.Customer.where('phone_number').equals(phone).toArray();
const getCustomerByShopxId = (db: ICashierWebDB, shopxId: string) => db.Customer.where('shopxId').equals(shopxId).toArray();

export const LOG_ITEMS_COUNT = 15;

const getAddPointsOperationsFromDB = async (db: ICashierWebDB, page = 0) => {
  const addPointsLogs = await db.AddPointsOperation
    .orderBy('created_at')
    .reverse()
    .offset(page * LOG_ITEMS_COUNT)
    .limit(LOG_ITEMS_COUNT)
    .toArray();
  const logsWithCustomers = addPointsLogs.map(async (log: any) => {
    if (typeof log.customer === 'string') {
      const myCustomer = await getCustomerById(db, log.customer);
      log.customer = myCustomer[0];
    }
    return log;
  });
  return await Promise.all(logsWithCustomers);
};

const getPendingOperations = (db: ICashierWebDB) => {
  return db.SyncActivity.orderBy('timestamp').reverse().toArray();
};

export const getPendingOperationsCustomers = async (db: ICashierWebDB, pendingOperations:ISyncActivity[]): Promise<PendingSyncActivity[]> => {
  const pendingOperationsWithCustomers = pendingOperations.map(async (operation) => {
    let customer;
    const {phoneNumber, shopxId} = operation.data;
    if (phoneNumber) {
      customer = await getCustomerByPhone(db, phoneNumber);
    } else if (shopxId) {
      customer = await getCustomerByShopxId(db, shopxId);
    }
    customer = customer[0] || phoneNumber;
    return new PendingSyncActivity({
      ...operation,
      customer,
    });
  });
  return await Promise.all(pendingOperationsWithCustomers);
};

function* retrieveLogAddPointsFromDB() {
  const db: ICashierWebDB = yield select(selectDB);
  const page: number = yield select(selectLogPage);
  let allOperations: Array<ISyncActivity | IAddPointsOperation>;
  const addPointsOperations: IAddPointsOperation[] = yield call(getAddPointsOperationsFromDB, db, page);
  allOperations = [...addPointsOperations];
  if (page === 0) {
    let pendingOperations: SyncActivity[] = yield call(getPendingOperations, db);
    pendingOperations = yield call(getPendingOperationsCustomers, db, pendingOperations);
    allOperations = [...pendingOperations, ...allOperations];
  }
  yield put(logAddPointsSuccess([...allOperations]));
}

const getAddPointsOperationsForComparisonFromDB = (db: ICashierWebDB) => {
  const yesterday = format(startOfDay(new Date()), 'x');
  const now = format(new Date(), 'x');
  return db.AddPointsOperation.where('created_at').between(yesterday, now).toArray();
};

function* retrieveLogAddPointsForComparisonFromDB() {
  const db: ICashierWebDB = yield select(selectDB);
  const addPointsOperationsForComparison: IAddPointsOperation[] = yield call(getAddPointsOperationsForComparisonFromDB, db);
  yield put(logAddPointsForComparisonSuccess(addPointsOperationsForComparison))
}

function* logAddPointsSaga() {
  try {
    yield* retrieveLogAddPointsFromDB();
    yield* retrieveLogAddPointsForComparisonFromDB();
  } catch (e) {
    yield put(logAddPointsFailure(e));
  }

}

export function* watchlogAddPointsSaga() {
  yield takeLatest(logAddPointsAction.requested, logAddPointsSaga);
}
