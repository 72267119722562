import * as actions from '../../constants';
import {
  ILogAddPointsAction,
  ILogAddPointsFailureAction,
  ILogAddPointsForComparisonSuccessAction,
  ILogAddPointsSuccessAction,
  ISyncActivity
} from "../../types/index";
import {IAddPointsOperation} from "../../data-model/types";

export const logAddPoints = (): ILogAddPointsAction => ({
  type: actions.logAddPointsAction.requested,
});

export const logAddPointsSuccess = (addPointsOperations: Array<IAddPointsOperation | ISyncActivity>): ILogAddPointsSuccessAction => ({
  type: actions.logAddPointsAction.fulfilled,
  payload: addPointsOperations
});

export const logAddPointsFailure = (error: Error): ILogAddPointsFailureAction => ({
  type: actions.logAddPointsAction.rejected,
  payload: error,
});

export const logAddPointsForComparisonSuccess = (addPointsOperations:IAddPointsOperation[]): ILogAddPointsForComparisonSuccessAction => ({
  type: actions.logAddPointsForComparisonAction.fulfilled,
  payload: addPointsOperations
});
