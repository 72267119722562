import {all} from "redux-saga/effects";
import {
  watchNetworkChangeHandlerSaga,
  watchOpenDBSaga,
  watchSyncSuccessSaga,
  watchSyncSaga,
  watchSwitchAccountSaga,
  watchGetCountriesSaga,
  watchLoginSaga,
  watchLogoutSaga,
  watchActivitySage,
  watchlogAddPointsSaga,
  watchlogRedeemPointsSaga,
  watchManagePointsSaga,
  watchNetworkChangeSaga,
  watchOpenSelectCallCenterBranchSaga,
  watchRedeemGiftSaga,
  watchSearchCustomersSaga,
  watchsgetAccountsSaga,
  watchVerifyCodeSaga,
} from './sagas';

export default function* rootSaga() {
    yield all([
      watchNetworkChangeHandlerSaga(),
      watchOpenDBSaga(),
      watchSyncSuccessSaga(),
      watchSyncSaga(),
      watchSwitchAccountSaga(),
      watchGetCountriesSaga(),
      watchLoginSaga(),
      watchLogoutSaga(),
      watchActivitySage(),
      watchlogAddPointsSaga(),
      watchlogRedeemPointsSaga(),
      watchManagePointsSaga(),
      watchNetworkChangeSaga(),
      watchOpenSelectCallCenterBranchSaga(),
      watchRedeemGiftSaga(),
      watchSearchCustomersSaga(),
      watchsgetAccountsSaga(),
      watchVerifyCodeSaga(),
    ]);
}
