import * as actions from '../../constants';
import {
  ILogoutAction, ILogoutFailureAction,
  ILogoutSuccessAction
} from "../../types";

export const logout = (): ILogoutAction => ({
  type: actions.logoutAction.requested,
});

export const logoutSuccess = (): ILogoutSuccessAction => ({
  type: actions.logoutAction.fulfilled
});

export const logoutFailure = (error: Error): ILogoutFailureAction => ({
  type: actions.logoutAction.rejected,
  payload: error,
});
