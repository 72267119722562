interface IGenericObject {
  [x: string]: any;
}

interface IOptionalObject {
  $isEmpty?: boolean;

  [x: string]: any;
}

export const optionalizeObject = (obj: IGenericObject): IOptionalObject => {
  return new Proxy(obj, {
    get: (target, p: string) => {
      return p in target ? target[p] : optionalizeObject({$isEmpty: true});
    }
  })
};
