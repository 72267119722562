import {getBranchesAction, openSelectBranchAction, selectBranchAction} from "./constants";
import {IBranch} from "../../data-model/types";

export interface ISelectBranchAction {
  type: typeof selectBranchAction.requested;
  payload: IBranch;
}

export interface ISelectBranchActionSuccess {
  type: typeof selectBranchAction.fulfilled;
  payload: IBranch;
}

export interface ISelectBranchActionFailure {
  type: typeof selectBranchAction.rejected;
  payload: Error;
}

export const selectBranch = (branch: IBranch): ISelectBranchAction => ({
  type: selectBranchAction.requested,
  payload: branch
});

export const selectBranchSuccess = (branch: IBranch): ISelectBranchActionSuccess => ({
  type: selectBranchAction.fulfilled,
  payload: branch,
});

export const selectBranchFailure = (error: Error): ISelectBranchActionFailure => ({
  type: selectBranchAction.rejected,
  payload: error,
});

export interface IOpenSelectBranchAction {
  type: typeof selectBranchAction.requested;
}

export interface IOpenSelectBranchActionSuccess {
  type: typeof selectBranchAction.fulfilled;
}

export interface IOpenSelectBranchActionFailure {
  type: typeof selectBranchAction.rejected;
}

export const openSelectBranch = (): IOpenSelectBranchAction => ({
  type: openSelectBranchAction.requested
});

export const openSelectBranchSuccess = (): IOpenSelectBranchActionSuccess => ({
  type: openSelectBranchAction.fulfilled,
});

export const openSelectBranchFailure = (): IOpenSelectBranchActionFailure => ({
  type: openSelectBranchAction.rejected,
});


export interface IGetBranches {
  type: typeof getBranchesAction.requested;
}

export interface IGetBranchesSuccess {
  type: typeof getBranchesAction.fulfilled;
  payload: IBranch[];
}

export interface IGetBranchesFailure {
  type: typeof getBranchesAction.rejected;
  payload: Error;
}

export const getBranches = (): IGetBranches => ({
  type: getBranchesAction.requested
});

export const getBranchesSuccess = (branches: IBranch[]): IGetBranchesSuccess => ({
  type: getBranchesAction.fulfilled,
  payload: branches,
});

export const getBranchesFailure = (error: Error): IGetBranchesFailure => ({
  type: getBranchesAction.rejected,
  payload: error,
});
