import {customerSearchField, ICustomerSearchAction, ICustomerSearchSuccessAction} from "./types";
import {searchCustomerAction} from "./constants";
import {ICustomer} from "../../data-model/types";

export const searchForCustomers = (field: customerSearchField, value: string): ICustomerSearchAction => ({
  type: searchCustomerAction.requested,
  payload: {
    field,
    value,
  }
});

export const searchForCustomersSuccess = (customers: ICustomer[]): ICustomerSearchSuccessAction => ({
  type: searchCustomerAction.fulfilled,
  payload: customers,
});

export const searchForCustomersFailure = (error: Error) => ({
  type: searchCustomerAction.rejected,
  payload: error,
});
