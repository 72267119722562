import {
  clearScreen,
  clickAgain,
  clickRedeem,
  codeInvalid,
  codeRedeemedSuccessfully,
  codeValid,
  enterPrizeCode,
  generate,
  redeemCode,
  redeemSuccess,
  verifyCode
} from "../../strings/RedeemGifts";

export default {
  [enterPrizeCode]: 'Enter redeem code',
  [verifyCode]: 'Check the code',
  [redeemCode]: 'Redeem gift',
  [codeInvalid]: 'CODE IS WRONG',
  [codeValid]: 'CODE IS VALID',
  [redeemSuccess]: 'Gift awarded successfully!',
  [clearScreen]:'clear screen',
  [clickRedeem]:'Click redeem',
  [clickAgain]:'Click on the gift again to',
  [generate]:'Generate a new code',
  [codeRedeemedSuccessfully]: 'Gift is redeemed!',
};
