import axios from './axios';
import * as Requests from './requests';
import {AxiosReturn} from "./types";
import { ISwitchAccountResponse} from "../types/index";

export const switchAccount = (token:string,toID: string, password: string): AxiosReturn<ISwitchAccountResponse> => {
  return axios.post(Requests.switchCashier, {
    toID,
    password,
  }, {headers:{token}})
};
