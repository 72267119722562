import axios from './axios';
import * as Requests from './requests';
import {ICustomer} from "../data-model/types/index";
import {AxiosReturn} from "./types";
import {customerSearchField} from "../components/SearchCustomers/types";


export const getCustomerInfo = (token: string, field: customerSearchField, value: string): AxiosReturn<{ customer: ICustomer }> => {
  if (!field || !value) {
    throw new TypeError('You must either pass a phone number or KoinzID');
  } else if (!token) {
    throw new TypeError('You must pass a valid token');
  }
  return axios.post(Requests.getCustomerInfo, {
    [field]: value,
  }, {
    headers: {
      token,
    }
  });
};

