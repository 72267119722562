import {ILogRedeemPointsAction, ILogRedeemPointsFailureAction, ILogRedeemPointsSuccessAction} from "../../types";
import * as actions from '../../constants';
import {IRedeemRewardOperation} from "../../data-model/types";

export const logRedeemPoints = (): ILogRedeemPointsAction => ({
  type:actions.logRedeemPointsAction.requested,
});

export const logRedeemPointsSuccess = (redeemPointsOperation:IRedeemRewardOperation[]): ILogRedeemPointsSuccessAction => ({
  type:actions.logRedeemPointsAction.fulfilled,
  payload:redeemPointsOperation
});

export const logRedeemPointsFailure = (error: Error): ILogRedeemPointsFailureAction => ({
  type:actions.logRedeemPointsAction.rejected,
  payload:error
});
