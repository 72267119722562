import {call, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {ACTION_CHANGE_SYNC_STATE, addSyncActivityAction, syncAppAction} from '../../constants';
import * as actions from '../actions';
import {syncApp, setSelectedCashierBranch} from '../actions';
import {selectDB, selectLastUpdatedTime, selectNetState, selectSyncState, selectToken, selectCashierBranchId, selectCallCenter} from "../selectors";
import {sync} from '../../axios'
import {ISyncActivity, ISyncSuccessResponse, NetworkState} from "../../types";
import handleSyncResponse from "./util/handleSyncResponse";
import {CashierWebDB} from "../../db/CashierWebDB";
import {ON_ONLINE} from "../../constants/netEvents";
import {notify} from "react-notify-toast";
import i18n from "../../i18n/i18n";
import {addPointsSuccess} from "../../i18n/strings/ManagePoints";
import {MANAGE_POINTS} from "../../i18n/strings/App";
import { initializeAppData, initializeCountryCode} from "./util/initalizeCountryCode";
import {currentOS} from "../../../Utils/getOS";
import {customNotify} from "../../helpers/customNotification/customNotification";
import { IRootReducerState } from "../rootReducer";
import { selectCallCenterBranch } from "../../web-components/ManagePoints/lib";

const getOperationsQueue = (db: CashierWebDB): Promise<ISyncActivity[]> => db ? db.SyncActivity.toArray() : Promise.resolve([]);
const clearDoneOperationsQueue = (db: CashierWebDB, operations: ISyncActivity[]): Promise<number> => {
  return db.SyncActivity.where('timestamp').anyOf(operations.map(a => a.timestamp)).delete();
};

const isMobile = currentOS === 'Mobile';

const notifyFailedActivity = (message) => {
  const failureMessage = i18n.t(message, {ns: 'logs'});
  if (isMobile) {
    customNotify.error(failureMessage, 3500);
  }
};

const notifySuccessfulActivity = () => {
  const successMessage = i18n.t(addPointsSuccess, {
    ns: MANAGE_POINTS,
  });
  if (isMobile) {
    customNotify.success(successMessage, 4000);
  } else {
    notify.show(successMessage, 'success');
  }
};

function* syncSaga() {
  try {

    const syncState = yield select(selectSyncState);
    const netState: NetworkState = yield select(selectNetState);
    if (syncState !== 1 && netState === ON_ONLINE) {
      // sync started
      yield put({type: ACTION_CHANGE_SYNC_STATE});
      // get the db instance from the memory
      const db: CashierWebDB = yield select(selectDB);
      const token: string = yield select(selectToken);
      const cashierBranch = yield select(selectCashierBranchId);
      // get the pending operations from the db
      const operations: ISyncActivity[] = yield call(getOperationsQueue, db);
      // get the last time when sync was successful
      const lastUpdatedTime: number = yield select(selectLastUpdatedTime);
      // call sync
      const syncResponse = yield call(sync, lastUpdatedTime, operations, token, cashierBranch);
      const syncData: ISyncSuccessResponse = syncResponse.data;
      // if there are still some operations
      if (operations.length) {
        notify.hide();
        // show the success of the sync operation notification
        if (!syncData.failed_activities) {
          notifySuccessfulActivity()
        } else {
          notifyFailedActivity(syncData.failed_activities[0].message);
        }
        // remove the successful operations
        yield call(clearDoneOperationsQueue, db, operations);
      }
      // go on to >> handleSyncResponse
      yield* handleSyncResponse(syncData);
      if (+lastUpdatedTime === 1) {
        yield* initializeCountryCode(db);
      }
      yield* initializeAppData();
    }
  } catch (error) {
    yield put(actions.syncAppFailure(error));
  }
}

function* syncSuccessSaga() {
  const selectedBranch = yield select((store: IRootReducerState) => store.authReducer.selected_branch);
  const isCallCenter = yield select(selectCallCenter);
  if (!isCallCenter && (!selectedBranch || !selectedBranch.id)) {
    const branch = yield* selectCallCenterBranch();
    yield put(setSelectedCashierBranch(branch));
  }
  const db: CashierWebDB = yield select(selectDB);
  const operations: ISyncActivity[] = yield call(getOperationsQueue, db);
  if (operations.length) {
    yield put(syncApp())
  }
}

export function* watchSyncSuccessSaga() {
  yield takeLatest(syncAppAction.fulfilled, syncSuccessSaga);
}


export function* watchSyncSaga() {
  yield takeEvery([
    syncAppAction.requested,
    addSyncActivityAction.fulfilled
  ], syncSaga);
}
