import {ICustomer} from "../../../data-model/types/index";
import {ICashierWebDB} from "../../../db/types";

export const queryCustomersLocally = (db: ICashierWebDB, field: string, value: string): Promise<ICustomer[]> => {
  return db.Customer
    .where(field)
    .startsWith(value)
    .limit(5)
    .toArray();
};
