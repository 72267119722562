import {IAppStoreState, IGetCountriesFailureAction, IGetCountriesSuccessAction} from '../../types';
import {union} from 'ts-action'
import * as constants from '../../constants';
import {ACTION_STORE_STORE} from '../../constants';
import {mutateState} from "../../helpers/mutate-state";
import {storeStore} from '../actions';

const COUNTRIES = 'countries';
const STORE = 'store';

const initialState: IAppStoreState = {
  [COUNTRIES]: [],
  [STORE]: {} as any
};

type getCountryAction = IGetCountriesSuccessAction | IGetCountriesFailureAction;
const storeStoreAction = union({storeStore});
type appStateAction = getCountryAction | typeof storeStoreAction;

export default (state = initialState, action: appStateAction): IAppStoreState => {
  switch (action.type) {
    case constants.getCountriesAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(COUNTRIES, (action as IGetCountriesSuccessAction).payload);
      });
    }
    case ACTION_STORE_STORE: {
      return mutateState(state, (map) => {
        map.set(STORE, (action as typeof storeStoreAction).payload)
      })
    }

    default:
      return state
  }
};
