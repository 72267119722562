import {call, put, select, takeLatest} from "redux-saga/effects";
import {getCountriesAction} from "../../constants";
import {getCountriesFailure, getCountriesSuccess} from "../actions";
import {selectDB} from "../selectors";
import {ICountry, IGetCountriesAction} from "../../types";
import {CashierWebDB} from "../../db/CashierWebDB";
import {getCountriesMetaData} from "../../web-components/AddPointsPhoneNumber/lib";


const putCountries = (db: CashierWebDB, countries: ICountry[]) => db.Country.bulkPut(countries);
const getCountriesFromDB = (db: CashierWebDB, page = 0, search = '') => {
  const processedSearchTerm = search.replace('+', '').trim();
  return db.Country
    .where('code')
    .startsWith(`+${processedSearchTerm}`)
    .or('en_name')
    .startsWithIgnoreCase(processedSearchTerm)
    .or('ar_name')
    .startsWith(processedSearchTerm)
    .toArray();
};
const getCountriesCount = (db: CashierWebDB) => db.Country.count();

function* putCountriesINDB(db: CashierWebDB) {
  const CountriesCollection = yield call(getCountriesMetaData);
  yield call(putCountries, db, CountriesCollection);
}

function* retrieveCountriesFromDB(action: IGetCountriesAction) {
  const db: CashierWebDB = yield select(selectDB);
  const { page, search } = action.payload;
  const countries: ICountry[] = yield call(getCountriesFromDB, db, page, search);
  yield put(getCountriesSuccess(countries))
}

function* getCountriesSaga(action: IGetCountriesAction) {
  try {
    const db: CashierWebDB = yield select(selectDB);
    const countriesCount: number = yield call(getCountriesCount, db);
    if (countriesCount === 0) {
      yield* putCountriesINDB(db);
    }
    yield* retrieveCountriesFromDB(action);

  } catch (e) {
    yield put(getCountriesFailure(e));
  }
}

export function* watchGetCountriesSaga() {
  yield takeLatest(getCountriesAction.requested, getCountriesSaga);
}
