const ENDPOINTS = {
  development: "https://api-core.koinz.app",
  test: "https://api-core.koinz.app",
  production: "https://api-core.koinz.app"
};
const VERSION_FIELD = "cashier";
const ENABLE_MOBILE = false;
const imagesURL = "https://api-core.koinz.app";

module.exports = {
  ENDPOINTS,
  VERSION_FIELD,
  imagesURL,
  ENABLE_MOBILE
};
