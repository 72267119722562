const createMap = (obj) => ({
  me: {...obj},
  set(key, value) {
    this.me = {
      ...this.me,
      [key]: value,
    };
  },
   get(key) {
    return this.me[key];
   }
});

export function mutateState(map, mutator: (map) => any) {
  const mutateMe = (obj) => {
    const myMap = createMap(obj);
    mutator(myMap);
    return myMap.me;
  };
  return {
    ...mutateMe(map),
  }
}
