import axios, {AxiosError, AxiosResponse} from "axios";
import {logout} from "../redux-store/actions/logout-actions";
import {store} from "../redux-store/configureStore";
import {switchCashier, login} from "./requests";
import {notify} from "react-notify-toast";
import i18n from "../../src/i18n/i18n";
import {checkInternet} from "../i18n/strings/axios";

const handleSuccess = (res: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => res;
const handleError = (error: AxiosError) => {
  const isSwitchingAccount: boolean = error.config.url === process.env.ENDPOINT + switchCashier;
  const isLogin = error.config.url === process.env.ENDPOINT + login;
  const myError = error.response as AxiosResponse;
  if (!myError) {
    notify.hide();
    notify.show(i18n.t(checkInternet,{
      ns: 'axios'
    }), 'error');
    throw error;
  }
  if ((error.response as AxiosResponse).status === 401 && !isSwitchingAccount && !isLogin) {
    store.dispatch(logout());
  }
  throw error;
};

const instance = axios.create({
  baseURL: process.env.ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  }

});

instance.interceptors.response.use(handleSuccess, handleError);

export default instance;
