import {IAddPointsOperation} from "../../data-model/types";
import {ILogAddPointsSuccessAction} from "../../types";
import * as constants from "../../constants";
import {mutateState} from "../../helpers/mutate-state";

export interface ILogAddPointsReducerState {
  addPointsOperations:IAddPointsOperation[],
  addPointsOperationsForComparison:IAddPointsOperation[],
  pageNumber:number
}

const ADD_POINTS_OPERATIONS = 'addPointsOperations';
const ADD_POINTS_OPERATIONS_FOR_COMPARISON = 'addPointsOperationsForComparison';
const PAGE_NUMBER='pageNumber';
// @ts-ignore
const initialeState: ILogAddPointsReducerState =  {
  [ADD_POINTS_OPERATIONS]:[],
  [ADD_POINTS_OPERATIONS_FOR_COMPARISON]:[],
  [PAGE_NUMBER]:0
};

export const logAddPointsReducer = (state: ILogAddPointsReducerState = initialeState,action: ILogAddPointsSuccessAction): ILogAddPointsReducerState =>{
  switch (action.type) {
    case constants.logAddPointsAction.fulfilled:{
      return mutateState(state, map => {
        const oldAddPoints = map.get(ADD_POINTS_OPERATIONS);
        const oldPageNumber:number = (map.get(PAGE_NUMBER) as number);
        if (oldPageNumber === 0) {
          map.set(ADD_POINTS_OPERATIONS,[...action.payload]);
        } else {
          map.set(ADD_POINTS_OPERATIONS,[...(oldAddPoints as []),...action.payload]);
        }
        map.set(PAGE_NUMBER,(oldPageNumber+1));
      })
    }
    case constants.logAddPointsForComparisonAction.fulfilled:{
      return mutateState(state,map => {
        map.set(ADD_POINTS_OPERATIONS_FOR_COMPARISON,[...action.payload]);
      })
    }
    case constants.ACTION_CHANGE_SYNC_STATE:{
      return mutateState(state,map => {
        map.set(PAGE_NUMBER,0)
      })
    }
    case constants.addSyncActivityAction.fulfilled: {
      return {
        ...state,
        addPointsOperations: [
          action.payload as any,
          ...state.addPointsOperations,
        ]
      }
    }
    default:
      return state;
  }
};

export default logAddPointsReducer;
