import ManagePoints from './ManagePoints/index';
import RedeemGifts from './RedeemGifts';
import {CURRENT_LANG, MANAGE_POINTS, pointsLog, REDEEM_GIFTS, redeemLog} from "../strings/App";
import Login from "./Login";
import Sync from "./Sync";
import logs from './logs'
import contactshopx from './contactshopx'
import switchAccount from './switchAccount'
import logout from './logout';
import axios from './axios';
import navbar from "./navbar";
import selectBranch from "./selectBranch";
import VerifyPasswordModal from "./VerifyPasswordModal";
export const ar = {
  translations: {
    [MANAGE_POINTS]: 'ادارة النقاط',
    [REDEEM_GIFTS]: 'تبديل الجوائز',
    [CURRENT_LANG]: 'English',
    [pointsLog]: 'سجل النقاط',
    [redeemLog]: 'سجل الهدايا',
  },
  [MANAGE_POINTS]: ManagePoints,
  [REDEEM_GIFTS]: RedeemGifts,
  Login,
  axios,
  Sync,
  logs,
  contactshopx,
  switchAccount,
  logout,
  navbar,
  selectBranch,
  VerifyPasswordModal,
};
