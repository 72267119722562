import {ICashier} from "../../data-model/types";
import {ICurrentAccountAction, IGetAccountsSuccessAction} from "../../types";
import * as constants from "../../constants";
import {mutateState} from "../../helpers/mutate-state";

export interface IGetAccountsReducerState {
  cashiers: ICashier[],
  currentCashier: ICashier
}

const CASHIERS = 'cashiers';
const CURRENT_CASHIER = 'currentCashier';
const initialeState: IGetAccountsReducerState = {
  [CASHIERS]: [],
  [CURRENT_CASHIER]: {} as any
};

export const getAccountsReducer = (state: IGetAccountsReducerState = initialeState, action: IGetAccountsSuccessAction | ICurrentAccountAction): IGetAccountsReducerState => {
  switch (action.type) {
    case constants.getAccountsAction.fulfilled: {
      return mutateState(state, map => {
        map.set(CASHIERS, action.payload);
      })
    }
    case constants.currentAccountAction.fulfilled: {
      return mutateState(state, map => {
        map.set(CURRENT_CASHIER, action.payload);
      })
    }
    default:
      return state;
  }
};

export default getAccountsReducer;
