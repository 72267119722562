import {call, put, select , takeLatest} from "redux-saga/effects";
import {searchCustomerAction} from "./constants";
import {ICashierWebDB} from "../../db/types";
import {ICustomer} from "../../data-model/types/index";
import {searchForCustomersFailure, searchForCustomersSuccess} from "./actions";
import {ICustomerSearchAction} from "./types";
import {selectDB, selectNetState} from "../../redux-store/selectors";
import {queryCustomersLocally} from "./util/queryCustomersLocally";
import {remoteSearchCustomersSaga} from "./util/queryCustomersRemotely";
import {ON_ONLINE} from "../../constants/netEvents";

function* searchCustomersSaga(action: ICustomerSearchAction) {
  try {
    if (action.payload.value) {
      const db: ICashierWebDB = yield select(selectDB);
      const {field, value} = action.payload;
      const results: ICustomer[] = yield call(queryCustomersLocally, db, field, value);
      yield put(searchForCustomersSuccess(results));
      if (results.length === 0) {
        const netState = yield select(selectNetState);
        if (netState === ON_ONLINE) {
          yield* remoteSearchCustomersSaga(action);
        }
      }
    } else {
      yield put(searchForCustomersSuccess([]));
    }
  } catch (error) {
    yield put(searchForCustomersSuccess([]));
    yield put(searchForCustomersFailure(error));
  }
}

export function* watchSearchCustomersSaga() {
  yield takeLatest(searchCustomerAction.requested, searchCustomersSaga);
}
