import {
  clearScreen,
  clickAgain,
  clickRedeem,
  codeInvalid,
  codeRedeemedSuccessfully,
  codeValid,
  enterPrizeCode,
  generate,
  redeemCode,
  redeemSuccess,
  verifyCode
} from "../../strings/RedeemGifts";

export default {
  [enterPrizeCode]: 'ادخل كود الجائزة',
  [verifyCode]: 'التأكد من صحة الكود',
  [redeemCode]: 'تبديل الجائزة',
  [codeInvalid]: 'الكود غير صالح',
  [codeValid]: 'الكود صالح',
  [redeemSuccess]: 'تم تبديل النقاط',
  [clearScreen]: 'الغاء',
  [clickRedeem]: 'أضغط تبديل',
  [clickAgain]:'اضعط مجددا على الجائزه',
  [generate]:'لتجديد الكود',
  [codeRedeemedSuccessfully]: 'تمت العملية بنجاح',
}
