import {IOpenDBSuccessAction} from '../../types';
import * as constants from '../../constants';
import {CashierWebDB} from "../../db/CashierWebDB";

export interface IDBReducerState {
  db: CashierWebDB | null;
  dbLoaded: boolean
}

const initialState: IDBReducerState = {
  db: null,
  dbLoaded: false,
};

const dbReducer = (
  state: IDBReducerState = initialState,
  action: IOpenDBSuccessAction
): IDBReducerState => {
  switch (action.type) {
    case constants.openDBAction.fulfilled: {
      const db = action.payload;
      return {
        db,
        dbLoaded: true,
      };
    }
    default:
      return state;
  }
};


export default dbReducer;
