import {Entity} from "./Entity";
import {ISyncActivity, SyncOperation, SyncService} from "../../types/index";

export class SyncActivity extends Entity implements ISyncActivity {
  public service: SyncService;
  public timestamp: number;
  public operation: SyncOperation;
  public data: {
    id: string;
    // here you can put your other data
  };

  constructor(data: ISyncActivity) {
    super();
    Object.assign(this, data);
  }

}
