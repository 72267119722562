import * as actions from '../../constants';
import {
  ILoginAction,
  ILoginFailureAction,
  ILoginResponse,
  ILoginSuccessAction,
  ISetSelectedCashierBranchAction
} from "../../types/index";
import {IBranch} from "../../data-model/types";

export const login = (): ILoginAction => ({
  type: actions.loginAction.requested,
});

export const loginSuccess = (data: ILoginResponse): ILoginSuccessAction => ({
  type: actions.loginAction.fulfilled,
  payload: data,
});

export const loginFailure = (error: Error): ILoginFailureAction => ({
  type: actions.loginAction.rejected,
  payload: error,
});

export const setSelectedCashierBranch = (branch:IBranch): ISetSelectedCashierBranchAction => ({
  type: actions.ACTION_SET_SELECTED_CASHIER_BRANCH,
  payload: branch
});
