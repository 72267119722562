import {getCustomerInfo} from "../../../axios";
import {customerSearchField, ICustomerSearchAction} from "../types";
import {selectForm, selectToken} from "../../../redux-store/selectors";
import {call, put, select} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {searchForCustomersSuccess} from "../actions";
import {ICustomer} from "../../../data-model/types";

const queryCustomersRemotely = (token: string, field: customerSearchField, value: string) => {
  return getCustomerInfo(token, field, value);
};

export function* remoteSearchCustomersSaga(action: ICustomerSearchAction) {
  const form = yield select(selectForm);
  if(!(form.AddPointsPhoneNumber.syncErrors || {}).phone_number) {
    const token: string = yield select(selectToken);
    const {field, value} = action.payload;
    const result: AxiosResponse<{ customer: ICustomer }> = yield call(queryCustomersRemotely, token, field, value);
    const {customer} = result.data;
    yield put(searchForCustomersSuccess([customer]));
  }
}
