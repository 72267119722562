import {call, put, select} from "redux-saga/effects";
import {selectForm} from "../../selectors";
import {storeStore} from "../../actions";
import reduxFormActions from "redux-form/es/actions";
const {initialize} = reduxFormActions;
import {CashierWebDB} from "../../../db/CashierWebDB";
import {optionalizeObject} from "./optionalizeObject";
import {ICashierWebDB} from "../../../db/types";
import * as actions from "../../actions";

const getStoreFromDB = (db: CashierWebDB) => db.Store.toArray();

export function* initializeCountryCode(db: ICashierWebDB) {
  const formState = yield select(selectForm);
  const AddPointsPhoneNumber = optionalizeObject(formState.AddPointsPhoneNumber || {});
  const shouldInitializeCode = AddPointsPhoneNumber.values.countryCode.$isEmpty === true;
  const stores = yield call(getStoreFromDB, db);
  yield put(storeStore(stores[0]));
  if (shouldInitializeCode && stores[0]) {
    yield put(initialize('AddPointsPhoneNumber', {
      countryCode: stores[0].country_code,
    }));
  }
}

export function* initializeAppData() {
  yield put(actions.logAddPoints());
  yield put(actions.logRedeemPoints());
  yield put(actions.getAccounts());
}
