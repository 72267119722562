import * as constants from '../../constants';
import {ACTION_CHANGE_SYNC_STATE} from '../../constants';
import {ISyncReducerState, SyncAction} from '../../types';
import {mutateState as mutateStateGen} from "../../helpers/mutate-state";
import {ON_OFFLINE, ON_ONLINE} from "../../constants/netEvents";


const mutateState = (state: ISyncReducerState, mutator: (map) => void): ISyncReducerState => {
  return mutateStateGen(state, mutator);
};
const SYNC_STATE = 'syncState';
const NETWORK_STATE = 'networkState';
const LAST_UPDATED_TIME = 'last_updated_time';

export const initialState: ISyncReducerState = {
  [SYNC_STATE]: 0,
  [NETWORK_STATE]: navigator.onLine ? ON_ONLINE : ON_OFFLINE,
  [LAST_UPDATED_TIME]: 1,
};

export default (state = initialState, action: SyncAction): ISyncReducerState => {
  switch (action.type) {
    case ACTION_CHANGE_SYNC_STATE: {
      return mutateState(state, map => {
        map.set(SYNC_STATE, 1);
      });
    }
    case constants.syncAppAction.fulfilled: {
      return mutateState(state, map => {
        map.set(LAST_UPDATED_TIME, action.payload.last_updated_time);
        map.set(SYNC_STATE, 0);
      });
    }
    case constants.syncAppAction.rejected: {
      return mutateState(state, map => {
        map.set(SYNC_STATE, -1);
      });
    }
    case constants.resetLastUpdatedTimeAction.requested:{
      return mutateState(state,map => {
        map.set(LAST_UPDATED_TIME,1);
      })
    }
    default:
      return state
  }
};
