import { Dexie, IndexableType } from 'dexie';

export class Entity {
    public static add<T, S>(table: Dexie.Table<T, S>, data: T):() => Promise<S> {
        return table.add.bind(table, data);
    }

    public static put<T, S>(table: Dexie.Table<T, S>, data: T):() => Promise<S> {
        return table.put.bind(table, data);
    }

    public static fetch<T, S>(table: Dexie.Table<T, S>, query: { [key: string]: IndexableType }):() => Dexie.Collection<T, S> {
        return table.where.bind(table, query);
    }

    public static remove<T, S>(table: Dexie.Table<T, S>, data: S):() => Promise<void> {
        return table.delete.bind(table, data);
    }

    public static update<T, S>(table: Dexie.Table<T, S>, data: T):() => Promise<S> {
        return table.put.bind(table, data);
    }

    public static bulkAdd<T, S>(table: Dexie.Table<T, S>, data: T[]):() => Promise<S> {
        return table.bulkAdd.bind(table, data);
    }

    public static bulkPut<T, S>(table: Dexie.Table<T, S>, data: T[]):() => Promise<S> {
        return table.bulkPut.bind(table, data);
    }

    public static bulkDelete<T, S>(table: Dexie.Table<T, S>, data: string[]):() => Promise<void> {
        return table.bulkDelete.bind(table, data);
    }

    public static clear<T, S>(table: Dexie.Table<T, S>):() => Promise<void> {
        return table.clear.bind(table);
    }

    public static count<T, S>(table: Dexie.Table<T, S>):() => Promise<number> {
        return table.count.bind(table);
    }
}
