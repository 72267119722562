import {
  ILoginResponse,
  ISwitchAccountAction,
  ISwitchAccountFailureAction,
  ISwitchAccountSuccessAction
} from "../../types";
import * as constants from "../../constants";
import {ICashier} from "../../data-model/types";

export const switchAccount = (cashier:ICashier): ISwitchAccountAction => ({
  type: constants.switchAccountAction.requested,
  payload:cashier
});

export const switchAccountSuccess = (userData: ILoginResponse): ISwitchAccountSuccessAction => ({
  type: constants.switchAccountAction.fulfilled,
  payload: userData,
});

export const switchAccountFailure = (error: Error): ISwitchAccountFailureAction => ({
  type: constants.switchAccountAction.rejected,
  payload: error,
});

export const switchModal = () => ({
  type: constants.switchModalAction,
});
