import {itemName, language, phoneNumber, points, price, sendingPoints,noInternet, errorCustomerBlocked} from "../strings/Logs";


export default {
  [price]:'Price: ',
  [points]:'Points: ',
  [language]:'en',
  [phoneNumber]:'Phone number: ',
  [itemName]: 'Product name: ',
  [sendingPoints]: 'Sending...',
  [noInternet]: 'Please reconnect to send points',
  'very large points operation': 'Rejected due to unreasonable amount!',
  [errorCustomerBlocked]: 'User blocked, contact your manager.',
};
