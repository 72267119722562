export const enterPrizeCode = 'enterPrizeCode';
export const verifyCode = 'verifyCode';
export const redeemCode = 'redeemCode';
export const codeInvalid = 'codeInvalid';
export const codeValid = 'codeValid';
export const redeemSuccess = 'redeemSuccess';
export const clearScreen = 'clearScreen';
export const clickRedeem = 'clickRedeem';
export const clickAgain = ' clickAgain';
export const generate='generate';
export const codeRedeemedSuccessfully = 'codeRedeemedSuccessfully';
