import {
  contact,
  contactMessage,
  contactTitle,
  haveProblem
} from "../strings/ContactShopx";

export default {
  [haveProblem]: "هل تواجه مشكله؟",
  [contact]: "اتصل ب",
  [contactTitle]: "تواصل معنا",
  [contactMessage]: "للتواصل مع شوبكس برجاء الاتصال بالرقم التالي: 01022913161"
};
