// 'Mac OS' | 'iOS' | 'Windows' | 'Android' | 'Linux'
const Mobile = 'Mobile';
const PC = 'PC';
type OSType = typeof PC | typeof Mobile;

export const getOS = (): OSType => {
  const userAgent = window.navigator.userAgent;
  const mobilePlatforms = ['iPhone', 'iPad', 'iPod', 'Android'];
  let os: OSType = 'PC';
  mobilePlatforms.forEach(platform => {
    if (userAgent.indexOf(platform) !== -1) {
      os = 'Mobile';
    }
  });
  return os;
};

export const currentOS  = getOS();
