import * as constants from '../../constants';
import {ICountry, IGetCountriesAction, IGetCountriesFailureAction, IGetCountriesSuccessAction} from "../../types/index";

export const getCountries = (payload: IGetCountriesAction['payload'] = {}): IGetCountriesAction => ({
  type: constants.getCountriesAction.requested,
  payload
});

export const getCountriesSuccess = (countries: ICountry[]): IGetCountriesSuccessAction => ({
  type: constants.getCountriesAction.fulfilled,
  payload: countries,
});

export const getCountriesFailure = (error: Error): IGetCountriesFailureAction => ({
  type: constants.getCountriesAction.rejected,
  payload: error,
});
