import * as constants from '../../constants';
import {IAuthAction, ILoginSuccessAction, ISetSelectedCashierBranchAction} from "../../types";
import {mutateState} from "../../helpers/mutate-state";
import {IBranch} from "../../data-model/types";

export interface IAuthReducerState {
  token: string;
  id: string;
  isLoggedIn: boolean;
  call_center: undefined | 1;
  selected_branch: IBranch;
}

const TOKEN = 'token';
const ID = 'id';
const SELECTED_BRANCH = 'selected_branch';
const IS_LOGGED_IN = 'isLoggedIn';
const CALL_CENTER = 'call_center';

const initialState: IAuthReducerState = {
  [TOKEN]: '',
  [IS_LOGGED_IN]: false,
  [ID]: '',
  [SELECTED_BRANCH]: {} as any,
  [CALL_CENTER]: undefined,
};

const authReducer = (state: IAuthReducerState = initialState, action: IAuthAction): IAuthReducerState => {
  switch (action.type) {
    case constants.loginAction.fulfilled:
    case constants.switchAccountAction.fulfilled: {
      return mutateState(state, map => {
        map.set(TOKEN, (action as ILoginSuccessAction).payload.token);
        map.set(ID, (action as ILoginSuccessAction).payload.id);
        map.set(IS_LOGGED_IN, true);
        map.set(CALL_CENTER, (action as ILoginSuccessAction).payload.call_center || 0);
      });
    }
    case constants.logoutAction.fulfilled: {
      return mutateState(state, map => {
        map.set(TOKEN, '');
        map.set(ID, '');
        map.set(IS_LOGGED_IN, false);
        map.set(CALL_CENTER, undefined as any);
        map.set(SELECTED_BRANCH, {});
      });
    }
    case constants.ACTION_SET_SELECTED_CASHIER_BRANCH: {
      return mutateState(state, map => {
        map.set(SELECTED_BRANCH, (action as ISetSelectedCashierBranchAction).payload);
      })
    }
    default:
      return state;
  }
};


export default authReducer;
