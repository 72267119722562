import axios from './axios';
import * as Requests from './requests';
import {AxiosReturn} from "./types";
import {ILoginResponse} from "../types";

export const login = (phoneNumber: string, password: string): AxiosReturn<ILoginResponse> => {
  return axios.post(Requests.login, {
    phoneNumber,
    password,
  })
};
