import axios from './axios';
import * as Requests from './requests';
import {AxiosReturn} from "./types";
import {IVerifyResponse} from "../types/index";

export const verifyCode = (code:string,token:string): AxiosReturn<IVerifyResponse> => {
  return axios.post(Requests.validateRedeemCode, {
    code
  },
    {headers:{token}})
};

