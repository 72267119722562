import {call, put, select, takeLatest} from "redux-saga/effects";
import {logoutAction} from "../../constants";
import {selectDB} from "../selectors";
import {logoutFailure, logoutSuccess} from "../actions/logout-actions";
import {CashierWebDB} from "../../db/CashierWebDB";
import {notify} from "react-notify-toast";
import i18n from "../../i18n/i18n";
import {logout} from "../../i18n/strings/App";
import {mustBeConnected} from "../../i18n/strings/logout";

const deleteDB = (db: CashierWebDB) => db.delete();

function* clearData() {
  const db: CashierWebDB = yield select(selectDB);
  yield call(deleteDB, db);
  Object.keys(localStorage)
    .forEach(key => key.includes('persist:') && localStorage.removeItem(key));
  window.location.reload()
}

function* logoutSaga() {
  try {
    if (window.navigator.onLine) {
      yield put(logoutSuccess());
      yield* clearData();
    } else {
      notify.show(i18n.t(
        mustBeConnected,{
          ns: logout
        }
      ), 'error')
    }
  } catch (e) {
    yield put(logoutFailure(e));
  }
}


export function* watchLogoutSaga() {
  yield takeLatest(logoutAction.requested, logoutSaga);
}
