import {
  click,
  current,
  loginIntoAccount,
  month,
  passwordPlaceholder,
  ranked,
  switchAccountSuccessful,
  switchAccountTitle,
  passwordWrong,
  transactions
} from "../strings/SwitchAccount";

export default {
  [current]: '(الحالى)',
  [transactions]: 'عمليه',
  [ranked]: 'الترتيب: ',
  [month]:'هذا الشهر',
  [click]:'اضغط لتغير الحساب',
  [switchAccountSuccessful]: 'تم تسجيل الدخول بنجاح!',
  [passwordWrong]: 'خظأ فى كلمة المرور!',
  [switchAccountTitle]: 'تغيير الحساب',
  [loginIntoAccount]: 'تسجيل الدخول',
  [passwordPlaceholder]: 'كلمة المرور...',
}
