import * as constants from '../../constants';
import {INetworkChangeAction, IListenToNetworkChangeAction, NetworkState} from '../../types';

export const listenToNetworkChange = (): IListenToNetworkChangeAction => ({
    type: constants.networkChangeAction.requested,
});

export const changeNetworkState = (isOnline: NetworkState): INetworkChangeAction => ({
    type: constants.networkChangeAction.fulfilled,
    payload: isOnline,
});
