import {fork, put, select, takeLatest} from 'redux-saga/effects';
import {readNetChange} from './channels/network-change';
import {networkChangeAction} from '../../constants';
import {syncApp} from "../actions";
import {INetworkChangeAction} from "../../types";
import {ON_ONLINE} from "../../constants/netEvents";
import {selectIsLoggedIn} from "../selectors";

function* networkChangeSaga() {
  try {
    yield fork(readNetChange);
  } catch (error) {
    throw error;
  }
}

export function* watchNetworkChangeSaga() {
  yield takeLatest(networkChangeAction.requested, networkChangeSaga);
}

function* networkChangeHandlerSaga(action: INetworkChangeAction) {
  const isLoggedIn: boolean = yield select(selectIsLoggedIn);
  if (action.payload === ON_ONLINE && isLoggedIn) {
    yield put(syncApp());
  }
}

export function* watchNetworkChangeHandlerSaga() {
  yield takeLatest(networkChangeAction.fulfilled, networkChangeHandlerSaga);
}
