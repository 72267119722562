import register from '../plugin-sw-precache/register-service-worker'
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.sass';
import persistedStore, {isDev} from './redux-store/configureStore';
import {Provider} from 'react-redux';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n/i18n';
import {init as SentryInit} from '@sentry/browser';
import {getOS} from "../Utils/getOS";
import loadable from "@loadable/component";
import {ENABLE_MOBILE} from '../env/environment';
import axios from 'axios'
import 'core-js/stable';
import 'regenerator-runtime/runtime';

const WebApp = loadable(() => import('./web-interface/web-app'));
const MobileApp = loadable(() => import('./mobile-interface/mobile-app'));
const onUpdate = () => {
  axios.get(`https://firestore.googleapis.com/v1/projects/cashier-web-app/databases/(default)/documents/version/${process.env.VERSION_FIELD}`)
    .then(async ({data}) => {
      const latestVersion = data.fields.current.stringValue;
      if (window.localStorage.getItem('appVersion') !== latestVersion) {
        localStorage.removeItem('persist:syncReducer');
        window.localStorage.setItem('appVersion', latestVersion);
        setTimeout(() => {
          location.reload();
        }, 100);
      }
    });
};


onUpdate();
register();
if (!isDev) {
  SentryInit({
   dsn: "https://92c18af8e70743df9010a690dfdaf0ec@sentry.io/1362353"
  });
}

const getApp = () => {
  if (!ENABLE_MOBILE) {
    return <WebApp />;
  }
  if (getOS() === 'Mobile') {
    return <MobileApp />;
  } else {
    return <WebApp />;
  }
};

export async function initApp() {
  const {store} = await persistedStore();
  ReactDOM.render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        {getApp()}
      </I18nextProvider>
    </Provider>,
    document.getElementById('root') as HTMLDivElement
  );

}

initApp();
