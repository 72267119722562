import {
  customerSearchAction,
  ICustomerSearchFailureAction,
  ICustomerSearchState,
  ICustomerSearchSuccessAction
} from "./types";
import {searchCustomerAction} from "./constants";
import {mutateState} from "../../helpers/mutate-state";

const CUSTOMERS = 'customers';
const ERROR = 'error';

const initialState: ICustomerSearchState = {
  [CUSTOMERS]: [],
  [ERROR]: undefined,
};

export const searchCustomersReducer = (state = initialState, action: customerSearchAction): ICustomerSearchState => {
  switch (action.type) {
    case searchCustomerAction.fulfilled: {
      return mutateState(state, map => {
        map.set(CUSTOMERS, (action as ICustomerSearchSuccessAction).payload);
      });
    }
    case searchCustomerAction.rejected: {
      return mutateState(state, map => {
        map.set(ERROR, (action as ICustomerSearchFailureAction).payload);
      });
    }
    default: {
      return state;
    }
  }
};
