export const genericAction = (action: string) => ({
  fulfilled: `${action}_FULFILLED`,
  rejected: `${action}_REJECTED`,
  requested: `${action}_REQUESTED`,
});

const ACTION_SYNC_APP = 'ACTION_SYNC_APP';
export const syncAppAction = genericAction(ACTION_SYNC_APP);

const ACTION_RESET_LAST_UPDATED_TIME = 'ACTION_RESET_LAST_UPDATED_TIME';
export const resetLastUpdatedTimeAction = genericAction(ACTION_RESET_LAST_UPDATED_TIME);

export const ACTION_CHANGE_SYNC_STATE = 'ACTION_CHANGE_SYNC_STATE';

const ACTION_OPEN_DB = 'ACTION_OPEN_DB';
export const openDBAction = genericAction(ACTION_OPEN_DB);

const ACTION_NETWORK_CHANGE = 'ACTION_NETWORK_CHANGE';
export const networkChangeAction = genericAction(ACTION_NETWORK_CHANGE);

const ACTION_LOGIN = 'ACTION_LOGIN';
export const loginAction = genericAction(ACTION_LOGIN);



const ACTION_ADD_SYNC_ACTIVITY = 'ACTION_ADD_SYNC_ACTIVITY';
export const addSyncActivityAction = genericAction(ACTION_ADD_SYNC_ACTIVITY);

const ACTION_GET_COUNTRIES = 'ACTION_GET_COUNTRIES';
export const getCountriesAction = genericAction(ACTION_GET_COUNTRIES);

const ACTION_REDEEM_GIFTS = 'ACTION_REDEEM_GIFTS';
export const redeemGiftsAction = genericAction(ACTION_REDEEM_GIFTS);

const ACTION_VERIFY_CODE = 'ACTION_VERIFY_CODE';
export const verifyCodeAction = genericAction(ACTION_VERIFY_CODE);
const ACTION_LOGOUT = 'ACTION_LOGOUT';
export const logoutAction = genericAction(ACTION_LOGOUT);

export const ACTION_RESET_REDEEM = 'ACTION_RESET_REDEEM';
const ACTION_LOG_ADD_POINTS = 'ACTION_LOG_ADD_POINTS';
export const logAddPointsAction = genericAction(ACTION_LOG_ADD_POINTS);

const ACTION_LOG_ADD_POINTS_FOR_COMPARISON = 'ACTION_LOG_ADD_POINTS_FOR_COMPARISON';
export const logAddPointsForComparisonAction = genericAction(ACTION_LOG_ADD_POINTS_FOR_COMPARISON);

const ACTION_LOG_REDEEM_POINTS = 'ACTION_LOG_REDEEM_POINTS';
export const logRedeemPointsAction = genericAction(ACTION_LOG_REDEEM_POINTS);
const ACTION_ADD_POINT = 'ACTION_ADD_POINT';
export const addPointAction = genericAction(ACTION_ADD_POINT);

const ACTION_RESET_LOG_ADD_POINTS = 'ACTION_RESET_LOG_ADD_POINTS';
export const resetLogAddPointsAction = genericAction(ACTION_RESET_LOG_ADD_POINTS);

const ACTION_RESET_LOG_REDEEM_Points = 'ACTION_RESET_LOG_REDEEM_Points';
export const resetLogRedeemPointsAction = genericAction(ACTION_RESET_LOG_REDEEM_Points);

export const ACTION_SELECT_CUSTOMER = 'ACTION_SELECT_CUSTOMER';
export const ACTION_CLEAR_CUSTOMER = 'ACTION_CLEAR_CUSTOMER';

const ACTION_GET_ACCOUNTS = 'ACTION_GET_ACCOUNTS';
export const getAccountsAction = genericAction(ACTION_GET_ACCOUNTS);

const ACTION_SWITCH_ACCOUNT = 'ACTION_SWITCH_ACCOUNT';
export const switchAccountAction = genericAction(ACTION_SWITCH_ACCOUNT);

const ACTION_CURRENT_ACCOUNT = 'ACTION_CURRENT_ACCOUNT';
export const currentAccountAction = genericAction(ACTION_CURRENT_ACCOUNT);

export const switchModalAction='ACTION_SWITCH_MODAL';

export const ACTION_STORE_STORE = 'ACTION_STORE_STORE';


export const ACTION_SET_SELECTED_CASHIER_BRANCH = 'ACTION_SET_SELECTED_CASHIER_BRANCH';
