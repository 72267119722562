import i18n from "../../i18n/i18n";
import {MANAGE_POINTS} from "../../i18n/strings/App";
import {phoneNumberField, receiptValueField} from "../../i18n/strings/ManagePoints";
import generateGUID from "../../../Utils/generateGUID";
import {put, take} from "redux-saga/effects";
import {
  ISelectBranchAction,
  openSelectBranch,
  openSelectBranchSuccess,
  selectBranchSuccess
} from "../SelectCallCenterBranch/actions";
import {selectBranchAction} from "../SelectCallCenterBranch/constants";

interface IGenericStringsObject {
  [x: string]: string;
}

export interface IOperationMetadata {
  receiptValue: string;
  countryCode?: string;
  receiptId?: string;
  phone_number?: string;
  shopxId?: string;
}

export const translateManagePoints = (termToTranslate: string) => {
  return i18n.t(termToTranslate, {
    ns: MANAGE_POINTS
  });
};

export const getReduxFormErrorMessage = (errorsMap: IGenericStringsObject) => {
  const labels = {
    phone_number: phoneNumberField,
    receiptValue: receiptValueField,
  };
  return Object.keys(errorsMap)
    .reduce((errorMsg, key, index, array) => {
      const noMoreErrors = array.length - 1 === index;
      const endingCharacter = noMoreErrors ? '.' : ', ';
      return errorMsg + `${translateManagePoints(labels[key])} ${translateManagePoints(errorsMap[key]).toLowerCase()}${endingCharacter}`;
    }, '');
};

export const processOperationData = (meta: IOperationMetadata, call_center, branch_id,) => {
  let data: any = {
    receipt: +meta.receiptValue,
    call_center,
    branch_id,
    receipt_code: meta.receiptId,
    id: generateGUID(),
  };
  if (meta.shopxId) {
    data = {
      ...data,
      shopxId: meta.shopxId,
    }
  } else {
    data = {
      ...data,
      countryCode: meta.countryCode,
      phoneNumber: meta.phone_number,
    }
  }
  return data;
};

export function* selectCallCenterBranch() {
  yield put(openSelectBranch());
  const action: ISelectBranchAction = yield take(selectBranchAction.requested);
  yield put(selectBranchSuccess(action.payload));
  yield put(openSelectBranchSuccess());
  return action.payload;
}
