import axios from './axios';
import * as Requests from './requests';
import {AxiosReturn} from "./types";
import {IRedeemResponse} from "../types/index";

export const redeemCode = (code: string, token: string, call_center, branch_id): AxiosReturn<IRedeemResponse> => {
  return axios.post(Requests.redeemReward, {
      code,
      call_center,
      branch_id
    },
    {headers: {token}})
};

