import {IAppStoreState, INetworkStateReducerState, ISyncAppAction, ISyncReducerState,} from '../types';
import {PersistConfig, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {combineReducers, Reducer} from 'redux';
import dbReducer, {IDBReducerState} from './reducers/dbReducer';
import appReducer from "./reducers/appReducer";
import syncReducer from "./reducers/syncReducer";
import {default as authReducer, IAuthReducerState} from "./reducers/authReducer";
import {ICustomerSearchState} from "../components/SearchCustomers/types";
import {searchCustomersReducer} from "../components/SearchCustomers/reducer";
import networkStateReducer from "./reducers/networkStateReducer";
import {FormState} from 'redux-form';
import form from 'redux-form/es/reducer';
import {IRedeemGiftReducerState, redeemGiftReducer} from "./reducers/redeemGiftsReducer";
import logAddPointsReducer, {ILogAddPointsReducerState} from "./reducers/logAddPointsReducer";
import logRedeemPointsReducer, {ILogRedeemPointsReducerState} from "./reducers/logRedeemPointsReducer";
import {loadingBarReducer} from "react-redux-loading-bar";
import {IManagePointsReducerState, managePointsReducer} from "../web-components/ManagePoints/reducer";
import getAccountsReducer, {IGetAccountsReducerState} from "./reducers/getAccounts";
import switchAccountReducer, {ISwitchAccountReducerState} from "./reducers/switchAccounts";
import {ISelectBranchReducerState, selectBranchReducer} from "../web-components/SelectCallCenterBranch/reducer";

export interface ICashierFormState {login: FormState, AddPointsPhoneNumber: FormState,switchAccount: FormState}

export interface IRootReducerState {
  appReducer: IAppStoreState;
  dbReducer: IDBReducerState;
  authReducer: IAuthReducerState;
  searchCustomersReducer: ICustomerSearchState;
  syncReducer: ISyncReducerState;
  networkStateReducer: INetworkStateReducerState;
  form: ICashierFormState;
  redeemGiftReducer:IRedeemGiftReducerState;
  logAddPointsReducer:ILogAddPointsReducerState;
  logRedeemPointsReducer:ILogRedeemPointsReducerState;
  getAccountsReducer:IGetAccountsReducerState;
  switchAccountReducer:ISwitchAccountReducerState;
  loadingBar: Reducer<any>,
  managePointsReducer: IManagePointsReducerState;
  selectBranchReducer: ISelectBranchReducerState;
}

const persistSync: PersistConfig = {
  key: 'syncReducer',
  storage,
  blacklist: ['syncState'],
};

const persistAuth: PersistConfig = {
  key: 'authReducer',
  storage,
};


const rootReducer = combineReducers<(Action: ISyncAppAction) => IRootReducerState>({
  appReducer,
  dbReducer,
  getAccountsReducer,
  logAddPointsReducer,
  switchAccountReducer,
  logRedeemPointsReducer,
  redeemGiftReducer,
  authReducer: persistReducer(persistAuth, authReducer),
  searchCustomersReducer,
  syncReducer: persistReducer(persistSync, syncReducer),
  networkStateReducer,
  loadingBar: loadingBarReducer,
  form,
  managePointsReducer,
  selectBranchReducer,
});

const persistorOptions: PersistConfig = {
  key: 'root',
  storage,
  whitelist: [
  ]
};

const persistedReducer = persistReducer(persistorOptions, rootReducer);

export default persistedReducer;
