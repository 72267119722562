import {eventChannel} from 'redux-saga';
import {changeNetworkState} from '../../actions';
import {watchInternet} from '../../../../Utils/helpers';
import {INetworkChangeAction, NetworkState} from '../../../types';
import {put, take} from "redux-saga/effects";
import {ON_OFFLINE, ON_ONLINE} from "../../../constants/netEvents";

const netChange = () => {
  return eventChannel<INetworkChangeAction>(emit => {
    emit(changeNetworkState(window.navigator.onLine ? ON_ONLINE : ON_OFFLINE));
    const onNetChange = (isOnline: NetworkState) => emit(changeNetworkState(isOnline));
    const unSubFromWatchInternet = watchInternet(onNetChange);
    return () => {
      unSubFromWatchInternet();
    }
  })
};

export function* readNetChange() {
  const channel = netChange();
  while (true) {
    const action: INetworkChangeAction = yield take(channel);
    yield put(action);
  }
}
