import {IAddSyncActivityAction, ISyncActivity} from "../../types";
import {CashierWebDB} from "../../db/CashierWebDB";
import {selectDB, selectForm} from "../selectors";
import {call, put, select, takeLatest} from "redux-saga/effects";
import {addSyncActivityFailure, addSyncActivitySuccess} from "../actions/activity-actions";
import {addSyncActivityAction} from "../../constants";
import i18n from "../../i18n/i18n";
import {pointsValidation, sendingPoints} from "../../i18n/strings/ManagePoints";
import {MANAGE_POINTS} from "../../i18n/strings/App";
import {clearCustomer} from "../../web-components/ManagePoints/actions";
import {notify} from "react-notify-toast";
import actions from "redux-form/es/actions";
import {syncApp} from "../actions";
import {getPendingOperationsCustomers} from "./log-add-points-saga";
import { IStore } from "../../data-model/types";

const queueOperation = (db: CashierWebDB, activity: ISyncActivity) => {
  return db.SyncActivity.add(activity);
};

function* activitySaga(action: IAddSyncActivityAction) {
  try {
    const db: CashierWebDB = yield select(selectDB);
    if (Number(action.payload.data.receipt) > 1e4) {
      notify.show(i18n.t(pointsValidation, {
        ns: MANAGE_POINTS,
      }), 'error' as any);
      throw new Error('too much man');
    }
    yield call(queueOperation, db, action.payload);

    notify.show(i18n.t(sendingPoints, {
      ns: MANAGE_POINTS,
    }), 'info' as any);
    const pendingOperation = yield call(getPendingOperationsCustomers, db, [action.payload]);
    yield put(addSyncActivitySuccess(pendingOperation[0]));
    yield put(clearCustomer());
    const {AddPointsPhoneNumber} = yield select(selectForm);
    const stores = yield call(() => db.Store.toArray());
    const store = stores[0] as IStore;
    const selectedCountryCode = action.payload.data.countryCode || AddPointsPhoneNumber.values.countryCode || store.country_code;
    yield put(actions.initialize('AddPointsPhoneNumber', {
      countryCode: selectedCountryCode,
      receiptValue: '',
      phone_number: '',
      receiptId: '',
      shopxId: '',
    }));
    yield put(syncApp());
  } catch (error) {
    yield put(addSyncActivityFailure(error));
  }
}

export function* watchActivitySage() {
  yield takeLatest(addSyncActivityAction.requested, activitySaga);
}
