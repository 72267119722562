import * as constants from '../../constants';
import {
  IVerifyCodeAction,
  IVerifyCodeFailureAction,
  IVerifyCodeSuccessAction,
  IVerifyResponse
} from "../../types/index";


export const verifyCode = (code:string): IVerifyCodeAction =>({
  type: constants.verifyCodeAction.requested,
  payload : code,
});

export const verifyCodeSuccess = (data:IVerifyResponse): IVerifyCodeSuccessAction =>({
  type: constants.verifyCodeAction.fulfilled,
  payload:data
});

export const verifyCodeFailure = (error:Error): IVerifyCodeFailureAction =>({
  type: constants.verifyCodeAction.rejected,
  payload:error,
});
