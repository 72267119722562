import * as constants from '../../constants';
import {INetworkStateReducerState, SyncAction} from '../../types';
import {mutateState as mutateStateGen} from "../../helpers/mutate-state";
import {ON_OFFLINE, ON_ONLINE} from "../../constants/netEvents";


const mutateState = (state: INetworkStateReducerState, mutator: (map) => void): INetworkStateReducerState => {
  return mutateStateGen(state, mutator);
};
const NETWORK_STATE = 'networkState';

export const initialState: INetworkStateReducerState = {
  [NETWORK_STATE]: navigator.onLine ? ON_ONLINE : ON_OFFLINE,
};

export default (state = initialState, action: SyncAction): INetworkStateReducerState => {
  switch (action.type) {
    case constants.networkChangeAction.fulfilled: {
      return mutateState(state, map => {
        map.set(NETWORK_STATE, action.payload);
      });
    }
    default:
      return state
  }
};
