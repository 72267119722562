import {
  IAddSyncActivityAction,
  IAddSyncActivityActionFailure,
  IAddSyncActivityActionSuccess,
  ISyncActivity
} from "../../types";
import {addSyncActivityAction} from "../../constants";
import {PendingSyncActivity} from "../../data-model/models/PendingSyncActivity";


export const addSyncActivity = (activity: ISyncActivity): IAddSyncActivityAction => ({
  type: addSyncActivityAction.requested,
  payload: activity,
});

export const addSyncActivitySuccess = (operation: PendingSyncActivity): IAddSyncActivityActionSuccess => ({
  type: addSyncActivityAction.fulfilled,
  payload: operation
});

export const addSyncActivityFailure = (error: Error): IAddSyncActivityActionFailure => ({
  type: addSyncActivityAction.rejected,
  payload: error,
});
