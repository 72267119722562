import {call, put, select, takeLatest} from "redux-saga/effects";
import {openSelectBranchAction} from "./constants";
import {getBranchesSuccess, openSelectBranchFailure} from "./actions";
import {CashierWebDB} from "../../db/CashierWebDB";
import {IBranch} from "../../data-model/types";
import {selectDB} from "../../redux-store/selectors";
import { IRootReducerState } from "../../redux-store/rootReducer";

const getCallCenterBranchesFromDB = (db: CashierWebDB) => db.Branch.orderBy('name').toArray();
const getAssignedBranchesFromDB = (db: CashierWebDB) => db.AssignedBranch.orderBy('name').toArray();

function* openSelectCallCenterBranchSaga() {
  try {
    const db: CashierWebDB = yield select(selectDB);
    const isCallCenter = yield select((store: IRootReducerState) => store.authReducer.call_center);
    if (isCallCenter) {
      const branches: IBranch[] = yield call(getCallCenterBranchesFromDB, db);
      yield put(getBranchesSuccess(branches.filter(b => !b.call_center && !b.deleted)))
    } else {
      const branches: IBranch[] = yield call(getAssignedBranchesFromDB, db);
      yield put(getBranchesSuccess(branches.filter(b => !b.call_center && !b.deleted)))      
    }
  } catch (e) {
    yield put(openSelectBranchFailure());
  }
}

export function* watchOpenSelectCallCenterBranchSaga() {
  yield takeLatest(openSelectBranchAction.requested, openSelectCallCenterBranchSaga);
}
