import {ICustomer} from "../../data-model/types";
import {IClearCustomerAction, ISelectCustomerAction} from "./actions";
import {ACTION_CLEAR_CUSTOMER, ACTION_SELECT_CUSTOMER} from "../../constants";
import {mutateState} from "../../helpers/mutate-state";

const SELECTED_CUSTOMER = 'SELECTED_CUSTOMER';

export interface IManagePointsReducerState {
  [SELECTED_CUSTOMER]: ICustomer | undefined;
}

const myMutator = (state: IManagePointsReducerState, mutator: (map) => any) => {
  return mutateState(state, mutator)
};

const initialState: IManagePointsReducerState = {
  [SELECTED_CUSTOMER]: undefined,
};

export const managePointsReducer = (state: IManagePointsReducerState = initialState, action: ISelectCustomerAction | IClearCustomerAction) => {
  switch (action.type) {
    case ACTION_CLEAR_CUSTOMER:
      return myMutator(state, map => map.set(SELECTED_CUSTOMER, undefined));
    case ACTION_SELECT_CUSTOMER:
      return myMutator(state, map => map.set(SELECTED_CUSTOMER, (action as ISelectCustomerAction).payload));
    default:
      return state;

  }
};
