export const points = 'points';
export const willBeAdded = 'willBeAdded';
export const phoneNumber = 'phoneNumber';
export const shopxId = 'shopxId';
export const enterPhoneNumber = 'enterPhoneNumber';
export const enterShopxId = 'enterShopxId';
export const addReceiptValue = 'addReceiptValue';
export const addReceiptId = 'addReceiptId';
export const addReceiptIdPlaceHolder = 'addReceiptIdPlaceHolder';
export const addPoints = 'addPoints';
export const addPointsSuccess = 'addPointsSuccess';
export const sendingPoints = 'sendingPoints';
export const pointsValidation = 'pointsValidation';
export const selectCountryTitle = 'selectCountryTitle';
export const selectBranchTitle = 'selectBranchTitle';
export const cancelSelectingCountry = 'cancelSelectingCountry';
export const searchForCountry = 'searchForCountry';
export const invalidPhoneFormat = 'invalidPhoneFormat';
export const notAPhoneNumber = 'notAPhoneNumber';
export const isRequired = 'isRequired';
export const tooShortField = 'tooShortField';
export const phoneNumberField = 'phoneNumberField';
export const receiptValueField = 'receiptValueField';
export const next = 'next';
export const back = 'back';
export const chooseMethod = 'subtitle';
export const success = 'success';
export const failed = 'failed';

