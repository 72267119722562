import {
  addPoints,
  addPointsSuccess,
  addReceiptId,
  addReceiptValue,
  cancelSelectingCountry,
  enterPhoneNumber,
  enterShopxId,
  invalidPhoneFormat,
  isRequired,
  notAPhoneNumber,
  phoneNumber,
  phoneNumberField,
  receiptValueField,
  searchForCountry,
  selectCountryTitle,
  sendingPoints,
  shopxId,
  pointsValidation,
  tooShortField,
  next,
  addReceiptIdPlaceHolder,
  points,
  willBeAdded, back, chooseMethod, success, failed, selectBranchTitle,
} from '../../strings/ManagePoints';

export default {
  [points]: '{{count}} Points',
  [willBeAdded]: 'will be added',
  [phoneNumber]: 'Phone no.',
  [shopxId]: 'Koinz ID',
  [back]: 'Back',
  [chooseMethod]: 'Choose the way to add points',
  [pointsValidation]: 'can\'t exceed 10,000',
  [enterPhoneNumber]: 'Customer\'s phone number',
  [enterShopxId]: 'Customer\'s Koinz ID',
  [addReceiptValue]: 'Receipt value',
  [addPoints]: 'Send Points',
  [addReceiptId]: 'Receipt ID',
  [addPointsSuccess]: 'Points sent!',
  [sendingPoints]: 'Sending points...',
  [cancelSelectingCountry]: 'Cancel',
  [searchForCountry]: 'Search...',
  [selectCountryTitle]: 'Select customer\'s country',
  [invalidPhoneFormat]: 'Must match the selected country code',
  [notAPhoneNumber]: 'Not a phone number',
  [isRequired]: 'Required',
  [tooShortField]: 'Too short',
  [phoneNumberField]: 'Phone Number is',
  [receiptValueField]: 'Receipt Value is',
  [next]: 'Next',
  [addReceiptIdPlaceHolder]: 'Enter Receipt ID',
  [success]: 'Success',
  [failed]: 'Failed',
  [selectBranchTitle]: 'Select Branch',
}
