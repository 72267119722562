import * as constants from '../../constants'
import { ISyncSuccessResponse, ISyncData, ISyncAppAction, ISyncSuccessResponseAction } from '../../types';

export const syncApp = (data: ISyncData = {}): ISyncAppAction => {
  return {
    type: constants.syncAppAction.requested,
    payload: data,
  }
};

export const syncAppSuccess = (data: ISyncSuccessResponse): ISyncSuccessResponseAction => {
  return {
    type: constants.syncAppAction.fulfilled,
    payload: data,
  }
};

export const syncAppFailure = (error: any): ISyncAppAction => {
  return {
    type: constants.syncAppAction.rejected,
    payload: error,
  }
};

export const resetLastUpdatedTime = () => ({
  type: constants.resetLastUpdatedTimeAction.requested
});

