import {IBranch} from "../../data-model/types";
import {IGetBranches, IGetBranchesSuccess, IOpenSelectBranchAction} from "./actions";
import {getBranchesAction, openSelectBranchAction} from "./constants";
import {mutateState} from "../../helpers/mutate-state";

const BRANCHES = 'BRANCHES';
const IS_MODAL_OPEN = 'IS_MODAL_OPEN';


export interface ISelectBranchReducerState {
  [BRANCHES]: IBranch[];
  [IS_MODAL_OPEN]: boolean;
}

type actions = IOpenSelectBranchAction | IGetBranches | IGetBranchesSuccess;

const initialState: ISelectBranchReducerState = {
  [BRANCHES]: [],
  [IS_MODAL_OPEN]: false,
};

export const selectBranchReducer = (state: ISelectBranchReducerState = initialState, action: actions): ISelectBranchReducerState => {
  switch (action.type) {
    case openSelectBranchAction.requested:
      return mutateState(state, map => map.set(IS_MODAL_OPEN, true));
    case openSelectBranchAction.fulfilled:
    case openSelectBranchAction.rejected:
      return mutateState(state, map => map.set(IS_MODAL_OPEN, false));
    case getBranchesAction.fulfilled:
      return mutateState(state, map => map.set(BRANCHES, (action as IGetBranchesSuccess).payload));
    default:
      return state;
  }
};
