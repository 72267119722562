import {call, put, select, takeLatest} from "redux-saga/effects";
import {selectDB, selectID,} from "../selectors";
import {CashierWebDB} from "../../db/CashierWebDB";
import {ICashier} from "../../data-model/types";
import {getAccountsAction} from "../../constants";
import {currentAccount, getAccountsFailure, getAccountsSuccess} from "../actions";

const getCashiersFromDB = (db: CashierWebDB) => {
  return db.Cashier.toArray();
};

function* retrieveCashiersFromDB() {
  const db: CashierWebDB = yield select(selectDB);
  const cashiers: ICashier[] = yield call(getCashiersFromDB, db);
  const currentCashierId: string = yield select(selectID);
  const rankedCashiers = cashiers
    .sort((a, b) => (b.monthlyOperationsCount as number) - (a.monthlyOperationsCount as number))
    .map((cashierItem, rank) => {
      return {
        ...cashierItem,
        rank: rank + 1,
      };
    });
  const currentCashier: any = rankedCashiers.find(c => c.id === currentCashierId);
  const otherCashiers = rankedCashiers.filter(c => c.id !== currentCashierId);
  yield put(currentAccount(currentCashier));
  yield put(getAccountsSuccess(otherCashiers));
}

function* getAccountsSaga() {
  try {
    yield* retrieveCashiersFromDB();

  } catch (e) {
    yield put(getAccountsFailure(e));
  }

}

export function* watchsgetAccountsSaga() {
  yield takeLatest(getAccountsAction.requested, getAccountsSaga);
}
