import {ILoginResponse} from "../../types";
import {call, put, select, takeLatest} from "redux-saga/effects";
import {loginAction} from "../../constants";
import {loginFailure, loginSuccess} from "../../redux-store/actions";
import {login} from "../../axios";
import {AxiosResponse} from "axios";
import {selectForm} from "../../redux-store/selectors";
import {ICashierFormState} from "../../redux-store/rootReducer";
import {notify} from 'react-notify-toast';
import reduxFormActions from 'redux-form/es/actions';

const {startSubmit, setSubmitSucceeded, setSubmitFailed, stopSubmit} = reduxFormActions;
import i18n from "../../i18n/i18n";
import {loginFailed, loginSuccessful} from "../../i18n/strings/Login";

function* loginSaga() {
  try {
    notify.show('...جاري تسجيل الدخول', 'info' as any, -1);
    const form: ICashierFormState = yield select(selectForm);
    yield put(startSubmit('login'));
    const errors = (form.login as any).syncErrors as { [x: string]: string };
    if (errors) {
      const validationMsg = Object.keys(errors)
        .reduce((errorMsg, key, index, array) => {
          return errorMsg + `${key.toLowerCase()} ${errors[key].toLowerCase()}${array.length - 1 === index ? '.' : ', '}`;
        }, '');
      notify.show(validationMsg, 'error');
      yield put(loginFailure(new Error(validationMsg)));
    }
    const {phoneNumber, password} = (form.login.values as { phoneNumber: string, password: string });
    if (phoneNumber && password) {
      const response: AxiosResponse<ILoginResponse> = yield call(login, phoneNumber, password);
      notify.hide();
      notify.show(i18n.t(loginSuccessful, {
          ns: 'Login'
        }
      ), 'success');
      yield put(setSubmitSucceeded('login'));
      yield put(loginSuccess(response.data));
    }
  } catch (e) {
    yield put(stopSubmit('login'));
    yield put(setSubmitFailed('login'));
    yield put(loginFailure(e));
    if (e.response && e.response.status === 401) {
      notify.hide();
      notify.show(i18n.t(loginFailed, {
          ns: 'Login'
        }
      ), 'error')
    }
  }
}

export function* watchLoginSaga() {
  yield takeLatest(loginAction.requested, loginSaga)
}
