import {put, select, takeLatest} from "redux-saga/effects";
import {addPointAction} from "../../constants";
import {addSyncActivity, addSyncActivityFailure} from "../../redux-store/actions/activity-actions";
import {selectCallCenter} from "../../redux-store/selectors";
import {SyncActivity} from "../../data-model/models/SyncActivity";
import {notify} from "react-notify-toast";
import {loginFailure} from "../../redux-store/actions";
import {getFormSyncErrors, getFormValues, reset} from "redux-form";
import {IAddPointsAction} from "./actions";
import {getReduxFormErrorMessage, IOperationMetadata, processOperationData, selectCallCenterBranch} from "./lib";


function* managePointsSaga(action: IAddPointsAction) {
  try {
    const errors = yield select(getFormSyncErrors('AddPointsPhoneNumber'));
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      const validationMsg = getReduxFormErrorMessage(errors);
      notify.show(validationMsg, 'error');
      yield put(loginFailure(new Error(validationMsg)));
    } else {
      const operationMeta: IOperationMetadata = yield select(getFormValues('AddPointsPhoneNumber'));
      const call_center: 1 | undefined = yield select(selectCallCenter);
      let branch_id: string | undefined;
      if (call_center) {
        const branch = yield* selectCallCenterBranch();
        branch_id = branch.id;
      }
      const data = processOperationData(operationMeta, call_center, branch_id);
      const activity = new SyncActivity({
        operation: "add",
        service: "points",
        timestamp: new Date().getTime(),
        data,
      });
      yield put(addSyncActivity(activity));
      action.payload();
      yield put(reset('AddPointsPhoneNumber'))
    }
  } catch (e) {
    yield put(addSyncActivityFailure(e));
  }
}

export function* watchManagePointsSaga() {
  yield takeLatest(addPointAction.requested, managePointsSaga);
}
