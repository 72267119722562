import {
  click,
  current,
  loginIntoAccount,
  month,
  passwordPlaceholder,
  ranked,
  switchAccountSuccessful,
  switchAccountTitle,
  passwordWrong,
  transactions
} from "../strings/SwitchAccount";

export default {
  [current]: '(Current)',
  [transactions]: 'Transactions',
  [ranked]: 'Ranked: ',
  [month]:'this month',
  [click]:'Click to switch account',
  [switchAccountSuccessful]: 'Login Succeeded!',
  [passwordWrong]: 'Password is wrong!',
  [switchAccountTitle]: 'Switch Account',
  [loginIntoAccount]: 'Login',
  [passwordPlaceholder]: 'Password...',
}
