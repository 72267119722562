import {IRedeemRewardOperation} from "../../data-model/types";
import {ILogRedeemPointsSuccessAction} from "../../types";
import * as constants from "../../constants";
import {mutateState} from "../../helpers/mutate-state";

export interface ILogRedeemPointsReducerState {
  redeemPointsOperations:IRedeemRewardOperation[],
  pageNumber:number
}

const REDEEM_POINTS_OPERATIONS = 'redeemPointsOperations';
const PAGE_NUMBER='pageNumber';
// @ts-ignore
const initialeState: ILogRedeemPointsReducerState =  {
  [REDEEM_POINTS_OPERATIONS]:[],
  [PAGE_NUMBER]:0
};

export const logRedeemPointsReducer = (state: ILogRedeemPointsReducerState = initialeState,action: ILogRedeemPointsSuccessAction): ILogRedeemPointsReducerState =>{
  switch (action.type) {
    case constants.logRedeemPointsAction.fulfilled:{
      return mutateState(state, map => {
        const oldRedeemPoints = map.get(REDEEM_POINTS_OPERATIONS);
        const oldPageNumber:number = (map.get(PAGE_NUMBER) as number);
        if (oldPageNumber === 0) {
          map.set(REDEEM_POINTS_OPERATIONS,[...action.payload]);
        } else {
          map.set(REDEEM_POINTS_OPERATIONS,[...(oldRedeemPoints as []),...action.payload]);
        }
        map.set(PAGE_NUMBER,(oldPageNumber+1));
      })
    }
    case constants.ACTION_CHANGE_SYNC_STATE:{
      return mutateState(state,map => {
        map.set(PAGE_NUMBER,0)
      })
    }
    default:
      return state;
  }
};

export default logRedeemPointsReducer;
