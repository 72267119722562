import {ISwitchAccountResponse} from "../../types";
import {call, put, select, takeLatest} from "redux-saga/effects";
import {switchAccountAction} from "../../constants";
import {AxiosResponse} from "axios";
import {selectCashier, selectDB, selectSwitchForm, selectToken} from "../selectors";
import {ICashierFormState} from "../rootReducer";
import {
  // openDB,
  // resetLastUpdatedTime,
  switchAccountFailure,
  switchAccountSuccess,
  switchModal
} from "../actions";
import {ICashier} from "../../data-model/types";
import {switchAccount} from "../../axios/switchAccount";
import {CashierWebDB} from "../../db/CashierWebDB";
// import {resetRedeem} from "../actions/redeem-gifts-actions";
import {notify} from "react-notify-toast";
import {passwordWrong, switchAccountSuccessful} from "../../i18n/strings/SwitchAccount";
import i18n from '../../i18n/i18n';

const deleteDB = (db: CashierWebDB) => db.delete();

function* clearData() {
  const db: CashierWebDB = yield select(selectDB);
  yield call(deleteDB, db);
  localStorage.removeItem('persist:syncReducer');
  location.reload();
}

function* switchAccountSaga() {
  try {
    const form: ICashierFormState = yield select(selectSwitchForm);
    const cashier: ICashier = yield select(selectCashier);
    const {password} = (form.switchAccount.values as { password: string });
    const token: string = yield select(selectToken);
    if (cashier.id && password) {
      const response: AxiosResponse<ISwitchAccountResponse> = yield call(switchAccount, token, cashier.id, password);
      yield put(switchAccountSuccess(response.data.data));
      yield put(switchModal());
      yield* clearData();
      // yield put(resetLastUpdatedTime());
      // yield put(openDB());
      // yield put(resetRedeem());
      notify.show(i18n.t(switchAccountSuccessful, {
        ns: 'switchAccount',
      }), 'success');
    }
  } catch (e) {
    yield put(switchAccountFailure(e));
    notify.show(i18n.t(passwordWrong, {
      ns: 'switchAccount',
    }), 'error');

  }
}

export function* watchSwitchAccountSaga() {
  yield takeLatest(switchAccountAction.requested, switchAccountSaga)
}
