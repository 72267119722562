import {ICustomer, IStoreItem} from "../../data-model/types";
import {IRedeemGiftAction, IRedeemGiftSuccessAction, IVerifyCodeAction, IVerifyCodeSuccessAction} from "../../types";
import * as constants from "../../constants";
import {mutateState} from "../../helpers/mutate-state";

const IS_VERIFIED = 'isVerified';
const IS_VERIFYING = 'isVerifying';
const IS_REDEEMED = 'isRedeemed';
const IS_REDEEMING = 'isRedeeming';
const CUSTOMER = 'customer';
const GIFT = 'gift';

export interface IRedeemGiftReducerState {
  [IS_VERIFIED]: boolean | null,
  [IS_VERIFYING]: boolean | null,
  [IS_REDEEMING]: boolean | null,
  [IS_REDEEMED]: boolean | null,
  [CUSTOMER]: ICustomer,
  [GIFT]: IStoreItem
}


const initialState: IRedeemGiftReducerState = {
  [IS_VERIFIED]: null,
  [IS_VERIFYING]: null,
  [IS_REDEEMED]: null,
  [IS_REDEEMING]: null,
  [CUSTOMER]: {} as any,
  [GIFT]: {} as any,

};

type redeemAction = IRedeemGiftAction | IVerifyCodeAction | IVerifyCodeSuccessAction | IRedeemGiftSuccessAction;
export const redeemGiftReducer = (state: IRedeemGiftReducerState = initialState, action: redeemAction): IRedeemGiftReducerState => {
  switch (action.type) {
    case constants.verifyCodeAction.fulfilled: {
      return mutateState(state, map => {
        map.set(IS_VERIFIED, true);
        map.set(IS_VERIFYING,false);
        map.set(CUSTOMER, (action as IVerifyCodeSuccessAction).payload.customer);
        map.set(GIFT, (action as IVerifyCodeSuccessAction).payload.item)
      });
    }
    case constants.verifyCodeAction.rejected: {
      return mutateState(state, map => {
        map.set(IS_VERIFIED, false);
        map.set(CUSTOMER, {});
        map.set(IS_VERIFYING,false);
        map.set(GIFT, {});
      })
    }
    case constants.verifyCodeAction.requested: {
      return mutateState(state, map => {
        map.set(IS_VERIFIED, null);
        map.set(IS_VERIFYING, true);
        map.set(IS_REDEEMING, null);
        map.set(IS_REDEEMED, null);
        map.set(CUSTOMER, {});
        map.set(GIFT, {});
      })
    }
    case constants.redeemGiftsAction.requested: {
      return mutateState(state, map => {
        map.set(IS_REDEEMING, true);
      })
    }
    case constants.redeemGiftsAction.fulfilled: {
      return mutateState(state, map => {
        map.set(IS_REDEEMED, false);
        map.set(IS_VERIFIED, null);
      })
    }
    case constants.redeemGiftsAction.rejected: {
      return mutateState(state, map => {
        map.set(IS_REDEEMED, false);
      })
    }
    case constants.ACTION_RESET_REDEEM:{
      return mutateState(state,map => {
        map.set(IS_VERIFIED,null);
        map.set(IS_REDEEMED,null);
        map.set(CUSTOMER,{});
        map.set(IS_VERIFYING, null);
        map.set(IS_REDEEMING, null);
        map.set(GIFT,{});
      })
    }
    default:
      return state;
  }
};

export default redeemGiftReducer;
