import {action, payload} from "ts-action";
import {ACTION_STORE_STORE} from "../../constants";
import {IStore} from "../../data-model/types";

export * from './sync-actions';
export * from './db-actions';
export * from './auth-actions';
export * from './internet-actions';
export * from './get-countries-actions';
export * from './log-add-points'
export * from './log-redeem-points'
export * from './get-accounts-actions'
export * from './switch-account-actions'

export const storeStore = action(ACTION_STORE_STORE, payload<IStore>());
