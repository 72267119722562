import * as actions from '../../constants';
import { Dexie } from 'dexie';

export const openDB = () => ({
    type: actions.openDBAction.requested,
});

export const openDBSuccess = (db: Dexie) => ({
    type: actions.openDBAction.fulfilled,
    payload: db,
});

export const openDBFailure = (error: Error) => ({
    type: actions.openDBAction.rejected,
    payload: error,
});
