import {itemName, language, phoneNumber, points, price, sendingPoints,noInternet, errorCustomerBlocked} from "../strings/Logs";


export default {
  [price]:'السعر: ',
  [points]:'النقاط: ',
  [language]:'ar',
  [phoneNumber]:'رقم الهاتف: ',
  [itemName]: 'اسم المنتج: ',
  [sendingPoints]: 'جارٍ إرسال النقاط',
  [noInternet]: 'رجاءاً قم بالاتصال بالانترنت للارسال',
  'very large points operation': 'تم رفض العملية بسبب عدم معقولية السعر!',
  [errorCustomerBlocked]: 'العميل محظور، قم بالتواصل مع مديرك.',
}
