import {
  ICurrentAccountAction,
  IGetAccountsAction,
  IGetAccountsFailureAction,
  IGetAccountsSuccessAction
} from "../../types";
import * as constants from "../../constants";
import {ICashier} from "../../data-model/types";

export const getAccounts = (): IGetAccountsAction => ({
  type: constants.getAccountsAction.requested,
});

export const getAccountsSuccess = (data: ICashier[]): IGetAccountsSuccessAction => ({
  type: constants.getAccountsAction.fulfilled,
  payload: data,
});

export const getAccountsFailure = (error: Error): IGetAccountsFailureAction => ({
  type: constants.getAccountsAction.rejected,
  payload: error,
});

export const currentAccount = (data:ICashier): ICurrentAccountAction => ({
  type: constants.currentAccountAction.fulfilled,
  payload:data
});
